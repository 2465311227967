export const tableColumns = [
  {
    name: 'username',
    field: 'username',
    label: 'Username',
  },
  {
    name: 'locale',
    field: 'locale',
    label: 'Locale',
  },
  {
    name: 'created-at',
    field: (row) => row?.createdAt?.date,
    label: 'Created at',
  },
  {
    name: 'updated-at',
    field: (row) => row?.updatedAt?.date,
    label: 'Updated at',
  },
  {
    name: 'active',
    field: 'active',
    label: 'Status',
  },
];
