<template>
  <d-view-card
    ref="view"
    :remove-function="usersController.removeUser"
    :fetch-function="usersController.findUsers"
    :filters="filters"
    :pagination="pagination"
    :filter-transformer="clearFilters"
    remove-confirm-title="Are you sure you want to delete this user(s)?"
    remove-success-title="User(s) successfully removed"
  >
    <template #default="{ loading, find, selectedData, removeHandler, data, filtersEntity }">
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row q-col-gutter-sm">
            <div>
              <d-user-select
                label="All users"
                class="col-md-3 col-6"
                dense
                filled
                hide-bottom-space
                hide-hint
                v-model="filtersEntity.userId"
                emit-value
                map-options
                :options="allUsers"
              />
            </div>
            <div>
              <d-user-select
                v-model="filtersEntity.agentId"
                :options="agents"
                dense
                hide-bottom-space
                hide-hint
                label="Only agents"
                filled
                emit-value
                map-options
                class="col-4"
              />
              <q-toggle
                :disable="!filtersEntity.agentId || !filtersEntity.agentId.length"
                v-model="filtersEntity.displayOperators"
                :dense="$q.screen.lt.md"
                class="col-lg-6 col-xs-12 q-mb-md"
                checked-icon="mdi-check"
                size="sm"
                label="Display all operators"
                unchecked-icon="mdi-close"
              />
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              outline
              color="primary"
              label="Report"
              @click="onReportHandler"
            />
            <q-btn color="primary" label="Apply" @click="find" />
          </q-card-actions>
        </q-card>
      </div>

      <div class="col-grow">
        <div class="q-pa-md">
          <d-users-table
            :items="data"
            :disable-remove-btn="!selectedData.length"
            :selected.sync="$refs.view && $refs.view.$data.selectedData"
            :grid="$q.screen.lt.md"
            @on-remove="removeHandler"
            @on-toggle-status="updateStatus"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DUsersTable } from '@/features/users-table';
import DViewCard from '@/layouts/view-card';
import DUserSelect from '@/features/user-select';
import { usersController as apiController } from '@/shared/api';
import {authUtils, notify, objectUtils} from '@/shared/utils';
import {DEFAULT_FILTERS_STATE} from "@/pages/users/list/config";
import withAgentsDictionary from "@/shared/mixins/withUsersDictionary";
import withReportLoader from "@/shared/mixins/withReportLoader";

export default {
  components: { DViewCard, DUsersTable, DUserSelect },
  mixins: [
    withAgentsDictionary,
    withReportLoader,
  ],
  data: () => ({
    filters: { ...DEFAULT_FILTERS_STATE },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: true,
    },
    usersController: apiController(authUtils.getRoleByHierarchy()),
  }),

  async mounted() {
    try {
      this.$refs.view.setLoading(true);
      await this.loadAgents();
      await this.loadUsers();
    } catch (e) {
      notify.error(e.message);
    } finally {
      this.$refs.view.setLoading(false);
    }
  },

  watch: {
    'filters.agentId'(newValue) {
      if (!newValue?.length) {
        this.filters.displayOperators = false;
      }
    },
  },

  methods: {
    clearFilters: objectUtils.removeEmptyValuesRecursively,
    async updateStatus({ active, permissions, ...props }) {
      try {
        this.$refs.view.setLoading(true);
        await this.usersController.updateUser({
          ...props,
          ...permissions,
          active: !active,
        });
        notify.success();
        await this.$refs.view.find();
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.$refs.view.setLoading(false);
      }
    },

    onReportHandler() {
      this.callPromptForEmail().onOk(async (email) => {
        try {
          await this.usersController.applyReport({
            filter: this.clearFilters(this.filters),
            email,
          });
          notify.success('Report request sent successfully');
        } catch (e) {
          notify.error('Something went wrong while sending a report request.');
        }
      });
    },
  },
};
</script>
