var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-view-card',{ref:"view",attrs:{"remove-function":_vm.usersController.removeUser,"fetch-function":_vm.usersController.findUsers,"filters":_vm.filters,"pagination":_vm.pagination,"filter-transformer":_vm.clearFilters,"remove-confirm-title":"Are you sure you want to delete this user(s)?","remove-success-title":"User(s) successfully removed"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var find = ref.find;
var selectedData = ref.selectedData;
var removeHandler = ref.removeHandler;
var data = ref.data;
var filtersEntity = ref.filtersEntity;
return [_c('div',{staticClass:"col-shrink row q-px-md q-pt-md"},[_c('q-card',{staticClass:"full-width"},[_c('q-card-section',{staticClass:"row q-col-gutter-sm"},[_c('div',[_c('d-user-select',{staticClass:"col-md-3 col-6",attrs:{"label":"All users","dense":"","filled":"","hide-bottom-space":"","hide-hint":"","emit-value":"","map-options":"","options":_vm.allUsers},model:{value:(filtersEntity.userId),callback:function ($$v) {_vm.$set(filtersEntity, "userId", $$v)},expression:"filtersEntity.userId"}})],1),_c('div',[_c('d-user-select',{staticClass:"col-4",attrs:{"options":_vm.agents,"dense":"","hide-bottom-space":"","hide-hint":"","label":"Only agents","filled":"","emit-value":"","map-options":""},model:{value:(filtersEntity.agentId),callback:function ($$v) {_vm.$set(filtersEntity, "agentId", $$v)},expression:"filtersEntity.agentId"}}),_c('q-toggle',{staticClass:"col-lg-6 col-xs-12 q-mb-md",attrs:{"disable":!filtersEntity.agentId || !filtersEntity.agentId.length,"dense":_vm.$q.screen.lt.md,"checked-icon":"mdi-check","size":"sm","label":"Display all operators","unchecked-icon":"mdi-close"},model:{value:(filtersEntity.displayOperators),callback:function ($$v) {_vm.$set(filtersEntity, "displayOperators", $$v)},expression:"filtersEntity.displayOperators"}})],1)]),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{attrs:{"outline":"","color":"primary","label":"Report"},on:{"click":_vm.onReportHandler}}),_c('q-btn',{attrs:{"color":"primary","label":"Apply"},on:{"click":find}})],1)],1)],1),_c('div',{staticClass:"col-grow"},[_c('div',{staticClass:"q-pa-md"},[_c('d-users-table',{attrs:{"items":data,"disable-remove-btn":!selectedData.length,"selected":_vm.$refs.view && _vm.$refs.view.$data.selectedData,"grid":_vm.$q.screen.lt.md},on:{"update:selected":function($event){return _vm.$set(_vm.$refs.view && _vm.$refs.view.$data, "selectedData", $event)},"on-remove":removeHandler,"on-toggle-status":_vm.updateStatus}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }