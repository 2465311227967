<template>
  <d-grid-card-wrapper :is-selected="item.selected">
    <q-card-section>
      <div class="grid-item__row">
        <div class="grid-item__title_inline">
          <q-checkbox dense v-model="item.selected" />
          <span class="q-mx-sm">
            {{ item.row.username }}
          </span>
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section>
      <template v-for="prop in simpleProps">
        <div :key="prop.name" class="grid-item__row">
          <div class="grid-item__title">
            {{ prop.label }}
          </div>
          <div class="grid-item__value">
            {{ prop.value }}
          </div>
        </div>
      </template>
      <div class="grid-item__row">
        <div class="grid-item__title">
          {{ item.colsMap['active'].label }}
        </div>
        <div class="grid-item__value">
          <q-toggle
            dense
            :value="item.row.active"
            checked-icon="mdi-check"
            color="green"
            unchecked-icon="mdi-close"
            @input="$emit('on-toggle-status', item.row)"
          />
        </div>
      </div>
      <div class="q-mt-md">
        <q-btn-group spread>
          <d-row-actions :item="item" page-name="UsersEdit" />
        </q-btn-group>
      </div>
    </q-card-section>
  </d-grid-card-wrapper>
</template>

<script>
import { DGridCardWrapper } from '@/features/grid-card-wrapper';
import { DRowActions } from '@/features/row-actions';

export default {
  components: { DRowActions, DGridCardWrapper },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    simpleProps: ({ item }) =>
      item.cols.filter(
        (el) =>
          el.name !== 'username' &&
          el.name !== 'action-btns' &&
          el.name !== 'active'
      ),
  },
};
</script>
