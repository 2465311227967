<template>
  <d-virtual-table
    :grid="grid"
    :columns="columns"
    :items="items"
    selection="multiple"
    with-action-btns
    :selected="selected"
    @update:selected="$emit('update:selected', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        :class="btnClass(true)"
        icon="mdi-pencil-outline"
        color="primary"
        label="Create user"
        :to="{ name: 'UsersCreate' }"
      />
    </template>
    <template v-slot:body-cell-active="{ props }">
      <q-td :props="props">
        <q-toggle
          :value="props.row.active"
          checked-icon="mdi-check"
          color="green"
          unchecked-icon="mdi-close"
          @input="$emit('on-toggle-status', props.row)"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-action-btns="{ props }">
      <q-td :props="props">
        <d-row-actions
          :item="props"
          page-name="UsersEdit"
        />
      </q-td>
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-card
        :item="props"
        @on-toggle-status="$emit('on-toggle-status', $event)"
      />
    </template>
  </d-virtual-table>
</template>

<script>
import { tableColumns as columns } from './config';
import { DGridCard } from './features';
import { DRowActions } from '@/features/row-actions';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';

export default {
  components: { DVirtualTable, DRowActions, DGridCard },
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disableRemoveBtn: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Array,
      default: () => undefined,
    },
  },
  mixins: [withActionTableClasses],
  data: () => ({
    columns,
  }),
};
</script>
