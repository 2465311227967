import { usersController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  data: () => ({
    usersController: usersController(authUtils.getRoleByHierarchy()),
    agents: [],
    allUsers: [],
  }),
  methods: {
    async loadAgents() {
      const { data } = await this.usersController.getUsers();
      this.agents = data.filter((user) =>
        user.roles.includes(ROLES.ROLE_AGENT)
      );
    },
    async loadUsers() {
      const { data } = await this.usersController.getUsers();
      this.allUsers = data;
    },
  },
};
